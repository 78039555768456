import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Headquarters.scss";
import query from "./query";
import PropTypes from "prop-types";
import { filterDataByLanguage } from "../../Utils/Language";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Headquarters = (props) => {
  const data = query();
  const headquartersPage = filterDataByLanguage(
    props.lang,
    data.allContentfulHeadquartersPage
  );

  return (
    <div className="headquarters-container">
      <Container>
        <Row className="hq-intro-row">
          <Col className="col-6 hq-title">{headquartersPage.title}</Col>
        </Row>
        <Row>
          <Col className="hq-intro-image">
            <GatsbyImage
              className="intro-image"
              image={getImage(headquartersPage.mainImage)}
              alt={headquartersPage.mainImage.title}
            />
          </Col>
        </Row>
        <Row className="hq-numbers-row">
          <Col className="col-12 col-lg-6">
            <div className="hq-number">{headquartersPage.areaNumber}</div>
            <div className="hq-number-label">{headquartersPage.areaLabel}</div>
          </Col>
          <Col className="col-12 col-md-6 col-lg-3 hq-rooms">
            <div className="hq-number">{headquartersPage.focusRoomNumber}</div>
            <div className="hq-number-label">
              {headquartersPage.focusRoomLabel}
            </div>
          </Col>
          <Col className="col-12 col-md-6 col-lg-3 hq-rooms">
            <div className="hq-number">
              {headquartersPage.meetingRoomNumber}
            </div>
            <div className="hq-number-label">
              {headquartersPage.meetingRoomLabel}
            </div>
          </Col>
        </Row>
        <Row className="hq-details-row">
          <Col className="col-12 col-lg-6 offset-lg-6">
            <ul className="hq-details">
              {headquartersPage.detailsList.map((el, i) => (
                <li key={"hq-detail-" + i} className="hq-detail">
                  {el.content}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row className="hq-images-row">
          <Col className="col-12 col-md-6 hq-image">
            <GatsbyImage
              className="hq-image-1"
              image={getImage(headquartersPage.image1)}
              alt={headquartersPage.image1.title}
            />
          </Col>
          <Col className="col-12 col-md-6 hq-image">
            <GatsbyImage
              className="hq-image-2"
              image={getImage(headquartersPage.image2)}
              alt={headquartersPage.image2.title}
            />
          </Col>
          <Col className="col-12 col-md-6 hq-image">
            <GatsbyImage
              className="hq-image-3"
              image={getImage(headquartersPage.image3)}
              alt={headquartersPage.image3.title}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Headquarters.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Headquarters;
