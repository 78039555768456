import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import Headquarters from "../../Components/Headquarters/Headquarters";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const HeadquartersPage = () => {
  return (
    <>
      <Seo locale="it" page="Headquarters" />
      <Header isDarkTheme={false} lang="it" />
      <HamburgerMenu />
      <Headquarters lang="it" />
      <Partnership lang="it" />
      <Footer lang="it" />
    </>
  );
};

export default HeadquartersPage;
