import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulHeadquartersPage {
        nodes {
          node_locale
          title
          mainImage {
            gatsbyImageData
            title
          }
          areaLabel
          areaNumber
          focusRoomLabel
          focusRoomNumber
          meetingRoomLabel
          meetingRoomNumber
          detailsList {
            content
          }
          image1 {
            title
            gatsbyImageData
          }
          image2 {
            title
            gatsbyImageData
          }
          image3 {
            title
            gatsbyImageData
          }
        }
      }
    }
  `);
};

export default query;
